import type { MendLanguageShortCode } from './languages';

// Intersection of languages supported by Mend and Zoom
export const zoomLanguageMap = {
  en: 'en-US',
  es: 'es-ES',
  fr: 'fr-FR',
  pt: 'pt-PT',
  zh: 'zh-CN',
} as const;

type ZoomLanguageShortCode = keyof typeof zoomLanguageMap;
export type ZoomLanguageCode = (typeof zoomLanguageMap)[ZoomLanguageShortCode];

export function isLanguageSupportedByZoom(
  shortCode: MendLanguageShortCode
): shortCode is ZoomLanguageShortCode {
  return shortCode in zoomLanguageMap;
}

export function getZoomMappedLanguage(
  shortCode: MendLanguageShortCode
): ZoomLanguageCode {
  return zoomLanguageMap[shortCode as ZoomLanguageShortCode] ?? 'en-US';
}
